<template>
  <v-card
    class="d-flex flex-column"
    :rounded="false"
    :max-width="width"
    :width="width"
    :height="height"
  >
    <v-card-text class="pa-0 grow">
      <media-preview
        :value="media"
        :height="previewHeight"
        :width="previewWidth"
        :max-height="previewHeight"
        :flat="true"
      />
    </v-card-text>

    <v-card-actions>
      {{ media.shortId }}
      <v-spacer></v-spacer>
      <v-btn
        v-if="allowDelete"
        icon
        x-small
        :title="$t('delete')"
        @click="$event => (dialog = true)"
      >
        <v-icon small>mdi-delete-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="canBeKeyImage"
        icon
        x-small
        :title="$t('makeKeyImage')"
        @click="makeKeyImage"
      >
        <v-icon small>mdi-image</v-icon>
      </v-btn>
      <confirmation
        v-model="dialog"
        :title="$t('mediaDelete')"
        :text="$t('mediaDeleteConfirm')"
        :confirm-text="$t('deleteYes')"
        :cancel-text="$t('cancel')"
        @confirm="onDelete"
        @cancel="dialog = false"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { isImage } from '@/services/media.service'
import confirmation from '@/components/dialogs/confirmation2'
import MediaPreview from '@/components/media/media-preview.vue'
import mediaApi from '@/services/api/media'
import personApi from '@/services/api/person'

export default {
  components: {
    confirmation,
    MediaPreview
  },
  props: {
    allowDelete: { type: Boolean, default: false },
    media: { type: Object, required: true },
    person: { type: Object, required: true },
    height: { type: Number, default: 150 },
    width: { type: Number, default: 150 }
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    canBeKeyImage() {
      return this.media && isImage(this.media)
    },

    previewWidth() {
      return this.width
    },
    previewHeight() {
      return this.height - 40
    }
  },
  methods: {
    async makeKeyImage() {
      try {
        await personApi.setKeyImage(this.person.id, this.media.id)
        console.log('key image changed(media-card)', this.media)
        this.$emit('key-image-changed', this.media)
      } catch (err) {
        this.$store.dispatch('messages/addError', err)
      }
    },
    async onDelete() {
      try {
        await mediaApi.delete(this.media.id, { personId: this.person.id })
        const index = this.person.media.findIndex(m => m.id === this.media.id)
        this.person.media.splice(index, 1)
        this.$emit('deleted', this.media)
      } catch (err) {
        this.$store.dispatch('messages/addError', err)
      }
    }
  }
}
</script>
