<template>
  <div v-if="model && !isLoading">
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>

      <v-btn
        plain
        :to="{ name: 'person-edit', params: { personId: model.id } }"
      >
        {{ $t('edit') }}
      </v-btn>
      <detail-menu :person="model" />
    </app-toolbar>
    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <flns-text-field :label="$t('id')" :text="model.shortId" />
        <flns-text-field :label="$t('name')" :text="model.name" />
        <flns-text-area :label="$t('description')" :text="model.description" />
        <flns-field-card
          :label="$t('collections')"
          v-if="model.collections.length > 0"
        >
          <div
            class="pl-0"
            v-for="collection in model.collections"
            :key="collection.id"
          >
            <v-btn
              plain
              :to="{
                name: 'collection-detail',
                params: { collectionId: collection.id }
              }"
              target="_blank"
              title="View Collection"
            >
              {{ collection.collectionShortId }}:
              {{ collection.collectionNumber }}
            </v-btn>
          </div>
        </flns-field-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card
          flat
          class="d-flex flex-column justify-center align-center"
          height="100%"
          width="100%"
          max-height="275"
        >
          <v-card-text
            v-if="keyImageId"
            class="d-flex flex-column justify-center align-center"
          >
            <media-preview
              :id="keyImageId"
              :max-height="300"
              :height="300"
              :width="300"
            />
          </v-card-text>
          <v-card-text v-else
            class="d-flex flex-column justify-center align-center">
            <v-img
              src="@/assets/avatar.png"
              height="300"
              max-height="300px"
              max-width="300px"
            ></v-img>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <timeline :person="model" />

    <media :person="model"
      @media-added="onMediaAdded"
      @key-image-changed="onKeyImageChanged"
      @deleted="onMediaDeleted"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import mediaPreview from '@/components/media/media-preview2'
import personApi from '@/services/api/person'
import detailMenu from './menu.vue'
import media from './media.vue'
import timeline from './timeline.vue'

export default {
  components: {
    appToolbar
    , media
    , detailMenu
    , mediaPreview
    , timeline },
  data() {
    return {
      isLoading: true,
      model: {},
      keyImageId: null
    }
  },
  computed: {
    crumbs() {
      const items = [
        {
          text: this.$t('people'),
          to: { name: 'people-index' },
          disabled: false
        }
      ]

      if (this.model) {
        items.push({ text: this.model.shortId, disabled: true })
        items.push({ text: this.model.name, disabled: true })
      }

      return items
    },
    personId() {
      return this.$route.params.personId
    },
    allMedia() {
      const media = [...this.model.media]

      const collectionMedia = this.model.collections.reduce(
        (acc, collection) => {
          return acc.concat(collection.media)
        },
        []
      )

      media.push(...collectionMedia)
      return media
    }
  },
  async mounted() {
    this.model = await personApi.fetch(this.personId)
    this.keyImageId = this.model.keyImageMediaItemId
    this.isLoading = false
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.returnToIndex()
    },
    async onMediaAdded(media) {
      if (media && media.length > 0 && !this.keyImageId) {
        this.keyImageId = media[0].id
        await personApi.setKeyImage(this.personId, this.keyImageId)
      }
    },
    async onMediaDeleted(media){
      if(this.keyImageId === media.id){
        await  personApi.removeKeyImage(this.personId)
        this.keyImageId = null
      }

      if(this.allMedia.length > 0){
        await personApi.setKeyImage(this.personId, this.allMedia[0].id)
        this.keyImageId = this.allMedia[0].id
      }
    },
    onKeyImageChanged(media) {
      this.keyImageId = media.id
    },
    async onSave(item) {
      await personApi.put(this.personId, item)
      this.addMessage({
        message: `${this.$t('person')} '${item.name}' ${this.$t(
          'hasBeenUpdated'
        )}`
      })
      this.returnToIndex()
    },
    returnToIndex() {
      this.$router.push({
        name: 'people-index'
      })
    }
  }
}
</script>

<style></style>
